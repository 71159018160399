import type { AppBarProps } from '@mui/material'
import { AppBar, Box, ButtonBase, IconButton, Toolbar } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { AccountPopover } from '~/components/app/AccountPopover'
import AvatarCircular from '~/components/app/AvatarCircular'
import { LanguageSelector } from '~/components/app/LanaguageSelector'
import { Logo } from '~/components/app/Logo'
import { useGetUser } from '~/hooks/query/useGetUser'
import { CmMenu } from '~/icons/CmMenu'

interface DashboardNavbarProps extends AppBarProps {
  onOpenSidebar?: () => void
}

const DashboardNavbarRoot = styled(AppBar)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    ...(
      theme.palette.mode === 'light'
        ? {
          boxShadow: theme.shadows[3]
        }
        : {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.divider,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          boxShadow: 'none'
        }
    )
  })
)

const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const [openPopover, setOpenPopover] = useState<boolean>(false)
  const user = useGetUser()

  const handleOpenPopover = (): void => {
    setOpenPopover(true)
  }

  const handleClosePopover = (): void => {
    setOpenPopover(false)
  }

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2
        }}
      >
        <AvatarCircular
          sx={{
            height: 40,
            width: 40
          }}
          src={user.avatarURL}
        />
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  )
}

export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onOpenSidebar, ...other } = props

  return (
    <>
      <DashboardNavbarRoot
        position='fixed'
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            height: {
              xs: 56,
              md: 64
            },
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <CmMenu sx={{ color: 'primary' }} />
          </IconButton>
          <Logo width={144} />
          <Box sx={{ flexGrow: 1 }} />
          <AccountButton />
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  )
}

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
}
