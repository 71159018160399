import { createSvgIcon } from '@mui/material/utils'

export const CmArrowDown = createSvgIcon(
  <>
    <path
      d='M21.8425 7.04638L21.5242 6.72809C21.3135 6.51739 20.9728 6.51739 20.7621 6.72809L12.0025 15.4922L3.23841 6.72809C3.02771 6.51739 2.68701 6.51739 2.47631 6.72809L2.15802 7.04638C1.94733 7.25708 1.94733 7.59778 2.15802 7.80848L11.617 17.2719C11.8277 17.4826 12.1684 17.4826 12.3791 17.2719L21.8381 7.80848C22.0532 7.59778 22.0532 7.25708 21.8425 7.04638Z'
    />
  </>,
  'CmArrowDown'
)
