import { createSvgIcon } from '@mui/material/utils'

export const CmMenu = createSvgIcon(
  <>
    <path
      d='M2 5.85714C2 5.26541 2.49746 4.78571 3.11111 4.78571H20.8889C21.5025 4.78571 22 5.26541 22 5.85714C22 6.44888 21.5025 6.92857 20.8889 6.92857H3.11111C2.49746 6.92857 2 6.44888 2 5.85714Z'
    />
    <path
      d='M2 12C2 11.4083 2.49746 10.9286 3.11111 10.9286H20.8889C21.5025 10.9286 22 11.4083 22 12C22 12.5917 21.5025 13.0714 20.8889 13.0714H3.11111C2.49746 13.0714 2 12.5917 2 12Z'
    />
    <path
      d='M2 18.1429C2 17.5511 2.49746 17.0714 3.11111 17.0714H20.8889C21.5025 17.0714 22 17.5511 22 18.1429C22 18.7346 21.5025 19.2143 20.8889 19.2143H3.11111C2.49746 19.2143 2 18.7346 2 18.1429Z'
    />
  </>,
  'Menu'
)
