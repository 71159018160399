import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import api from '~/api'
import type { AppThunk } from '~/store'
import { User } from '~/types/user'

export interface UserState {
  user: User
}

const initialState: UserState = {
  user: null
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser(state: UserState, action: PayloadAction<User>): void {
      state.user = action.payload
    }
  }
})

export const { reducer } = slice

export const getUser = (): AppThunk =>
  async (dispatch) => {
    const resUser = await api.user.getUser()
    dispatch(slice.actions.getUser(resUser))
    return resUser
  }

export const patchUser = (payload: { user: Partial<User> }): AppThunk =>
  async (dispatch) => {
    await api.user.patchUser(payload)
    await dispatch(getUser())
    return true
  }

export const postUserOnBoarding = (payload: { user: Partial<User> }): AppThunk =>
  async (dispatch) => {
    const resUser = await api.user.postUserOnBoarding(payload)
    dispatch(slice.actions.getUser(resUser))
    return resUser
  }

export const patchUserAvatar = (payload): AppThunk =>
  async (dispatch) => {
    const resUser = await api.user.patchUserAvatar(payload)
    dispatch(slice.actions.getUser(resUser))
    return resUser
  }
