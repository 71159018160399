import {
  Box,
  Card,
  Container,
  Divider,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Scrollbar } from '~/components/app/Scrollbar'
import SettingSidebar from '~/components/setting/SettingSidebar'

interface SettingLayoutProps {
  children?: ReactNode
}

const SettingLayoutRootWithNav = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: 'calc(100vh - 32px - 64px)',
  maxWidth: '100%',
  overflow: 'hidden'
})

const Wrap = ({ children }) => (
  <Card>
    <Box sx={{ p: 3 }}>
      {children}
    </Box>
  </Card>
)

const WrapWithNav = ({ children }) => {
  const { t } = useTranslation()

  return (
    <SettingLayoutRootWithNav>
      <Card sx={{ display: 'flex', height: '100%' }}>
        <Box
          sx={{
            flexShrink: 0,
            width: 270,
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: 'neutral.500',
          }}
        >
          <Box sx={{ p: 2.5 }}>
            <Typography variant='subtitle1'>
              {t('setting.title')}
            </Typography>
          </Box>
          <Divider />
          <SettingSidebar />
        </Box>

        <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
          <Scrollbar
            sx={{
              height: '100%',
              flexGrow: 1,
              '& .simplebar-content': {
                height: '100%'
              },
              '& .simplebar-content-wrapper': {
                overscrollBehaviorY: 'contain'
              }
            }}
          >
            <Box
              sx={{
                px: 5,
                py: 3,
              }}
            >
              <Container maxWidth='md' disableGutters sx={{ ml: 0 }}>
                {children}
              </Container>
            </Box>
          </Scrollbar>
        </Box>
      </Card>
    </SettingLayoutRootWithNav>
  )
}

export const SettingLayout: FC<SettingLayoutProps> = (props) => {

  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const ResponsiveWrap = isMdUp ? WrapWithNav : Wrap

  return (
    <>
      <main>
        <ResponsiveWrap>
          <Outlet />
        </ResponsiveWrap>
      </main>
    </>
  )
}

SettingLayout.propTypes = {
  children: PropTypes.node
}
