import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import toast from 'react-hot-toast'
import { Link as RouterLink } from 'react-router-dom'
import AvatarCircular from '~/components/app/AvatarCircular'
import { useGetUser } from '~/hooks/query/useGetUser'
import { useAuth } from '~/hooks/useAuth'
import { CmLogout } from '~/icons/CmLogout'
import { CmProfile } from '~/icons/CmProfile'

interface AccountPopoverProps {
  anchorEl: null | Element
  onClose?: () => void
  open?: boolean
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props
  const { signOut } = useAuth()
  const user = useGetUser()

  const handleSignOut = async (): Promise<void> => {
    try {
      onClose?.()
      await signOut()
      location.replace('/signin')
    } catch (err) {
      console.error(err)
      toast.error('Unable to logout.')
    }
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex'
        }}
      >
        <AvatarCircular src={user.avatarURL} />
        <Box
          sx={{
            ml: 1
          }}
        >
          <Typography variant='body1'>
            {user?.name}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <MenuItem component={RouterLink} to={'/setting/account'}>
          <ListItemIcon>
            <CmProfile fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant='body1'>
                Profile
              </Typography>
            )}
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <CmLogout fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant='body1'>
                Logout
              </Typography>
            )}
          />
        </MenuItem>
      </Box>
    </Popover>
  )
}

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
}
