import { createSvgIcon } from '@mui/material/utils'

export const CmGlobe = createSvgIcon(
  <>
    <path
      d='M12 2C6.47581 2 2 6.47581 2 12C2 17.5242 6.47581 22 12 22C17.5242 22 22 17.5242 22 12C22 6.47581 17.5242 2 12 2ZM19.7903 8.12903H16.4637C16.1008 6.33871 15.4919 4.81452 14.7218 3.72984C16.9395 4.46371 18.7621 6.06452 19.7903 8.12903ZM15.5484 12C15.5484 12.9234 15.4839 13.7823 15.375 14.5806H8.625C8.51613 13.7823 8.45161 12.9234 8.45161 12C8.45161 11.0766 8.51613 10.2177 8.625 9.41935H15.375C15.4839 10.2177 15.5484 11.0766 15.5484 12V12ZM12 3.29032C13.0847 3.29032 14.4758 5.06855 15.1492 8.12903H8.85081C9.52419 5.06855 10.9153 3.29032 12 3.29032ZM9.27823 3.72984C8.5121 4.81048 7.89919 6.33468 7.53629 8.12903H4.20968C5.2379 6.06452 7.06048 4.46371 9.27823 3.72984V3.72984ZM3.29032 12C3.29032 11.1008 3.42742 10.2339 3.68145 9.41935H7.33064C7.22581 10.246 7.16129 11.1048 7.16129 12C7.16129 12.8952 7.22177 13.754 7.33064 14.5806H3.68145C3.42742 13.7661 3.29032 12.8992 3.29032 12ZM4.20968 15.871H7.53629C7.89919 17.6613 8.50806 19.1855 9.27823 20.2702C7.06048 19.5363 5.2379 17.9355 4.20968 15.871ZM12 20.7097C10.9153 20.7097 9.52419 18.9315 8.85081 15.871H15.1492C14.4758 18.9315 13.0847 20.7097 12 20.7097V20.7097ZM14.7218 20.2702C15.4879 19.1895 16.1008 17.6653 16.4637 15.871H19.7903C18.7621 17.9355 16.9395 19.5363 14.7218 20.2702ZM16.6694 14.5806C16.7742 13.754 16.8387 12.8952 16.8387 12C16.8387 11.1048 16.7782 10.246 16.6694 9.41935H20.3185C20.5726 10.2339 20.7097 11.1008 20.7097 12C20.7097 12.8992 20.5726 13.7661 20.3185 14.5806H16.6694Z'
    />
  </>
  ,
  'CmGlobe'
)
