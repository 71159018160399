import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '~/api'
import type { AppThunk } from '~/store'
import { getUser } from '~/store/slices/user'
import { Team, TeamInvitation, TeamMember, TeamRole } from '~/types/team'

export interface TeamState {
  team: Team,
  teamMemberList: TeamMember[],
  teamInvitationList: TeamInvitation[]
}

const initialState: TeamState = {
  team: null,
  teamMemberList: [],
  teamInvitationList: []
}

const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    getTeam(state: TeamState, action: PayloadAction<Team>): void {
      state.team = action.payload
    },
    getTeamMemberList(state: TeamState, action: PayloadAction<TeamMember[]>): void {
      state.teamMemberList = action.payload
    },
    getTeamInvitationList(state: TeamState, action: PayloadAction<TeamInvitation[]>): void {
      state.teamInvitationList = action.payload
    }
  }
})

export const { reducer } = slice

export const getTeam = (payload): AppThunk =>
  async (dispatch) => {
    const resTeam = await api.team.getTeam({ teamId: payload.teamId })
    dispatch(slice.actions.getTeam(resTeam))
    return resTeam
  }

export const patchTeam = (payload: { teamId: string, team: Partial<Team> }): AppThunk =>
  async (dispatch) => {
    await api.team.patchTeam(payload)
    await dispatch(getTeam({ teamId: payload.teamId }))
    return true
  }

export const postCreateTeam = (payload: { team: Partial<Team> }): AppThunk =>
  async (dispatch) => {
    await api.team.postCreateTeam(payload)
    await dispatch(getUser())
    return true
  }

export const getTeamMembers = (payload: { teamId: string }): AppThunk =>
  async (dispatch): Promise<{ members: TeamMember[] }> => {
    const resData = await api.team.getTeamMembers(payload)
    dispatch(slice.actions.getTeamMemberList(resData.members))
    return resData
  }

export const patchTeamMember = (payload: { teamId: string, memberId: string, role: TeamRole }): AppThunk =>
  async (dispatch) => {
    await api.team.patchTeamMember(payload)
    await dispatch(getUser())
    return true
  }

export const deleteTeamMember = (payload: { teamId: string, memberId: string }): AppThunk =>
  async (dispatch) => {
    await api.team.deleteTeamMember(payload)
    return true
  }

export const getTeamInvitations = (payload: { teamId: string }): AppThunk =>
  async (dispatch) => {
    const resData = await api.team.getTeamInvitations(payload)
    dispatch(slice.actions.getTeamInvitationList(resData.invitations))
    return resData
  }

export const postMemberInvitation = (payload: { teamId: string, email: string, role: TeamRole }): AppThunk =>
  async (dispatch) => {
    await api.team.postMemberInvitation(payload)
    await dispatch(getTeamInvitations({ teamId: payload.teamId }))
    return true
  }

export const postResendMemberInvitation = (payload: { teamId: string, invitationId: string }): AppThunk =>
  async (dispatch) => {
    await api.team.postResendMemberInvitation(payload)
    await dispatch(getTeamInvitations({ teamId: payload.teamId }))
    return true
  }

export const deleteMemberInvitation = (payload: { teamId: string, invitationId: string }): AppThunk =>
  async (dispatch) => {
    await api.team.deleteMemberInvitation(payload)
    await dispatch(getTeamInvitations({ teamId: payload.teamId }))
    return true
  }

export const postAcceptMemberInvitation = (payload: { teamId: string, invitationId: string }): AppThunk =>
  async (dispatch) => {
    await api.team.postAcceptMemberInvitation(payload)
    await dispatch(getUser())
    return true
  }

export const getValidateMemberInvitation = (payload: { teamId: string, invitationId: string }): AppThunk =>
  async (dispatch) => {
    return await api.team.getValidateMemberInvitation(payload)
  }

export const patchTeamLogo = (payload: { teamId: string, formData: FormData }): AppThunk =>
  async (dispatch) => {
    const resTeam = await api.team.patchTeamLogo(payload)
    dispatch(slice.actions.getTeam(resTeam))
    return resTeam
  }
