import { combineReducers } from '@reduxjs/toolkit'
import { reducer as userReducer } from '~/store/slices/user'
import { reducer as teamReducer } from '~/store/slices/team'
import { reducer as channelReducer } from '~/store/slices/channel'

export const rootReducer = combineReducers({
  user: userReducer,
  team: teamReducer,
  channel: channelReducer
})
