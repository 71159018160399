import axios, { AxiosRequestConfig } from 'axios'
import { getIdToken } from 'firebase/auth'
import Cookies from 'js-cookie'
import { appConfig, urlConfig } from '~/config'
import cookieName from '~/constants/cookieName'
import { auth } from '~/lib/firebase'

const createClient = (options: AxiosRequestConfig = {}) => {
  const instance = axios.create({
    timeout: 30000,
    ...options,
  })

  instance.interceptors.request.use(
    async (config) => {
      const token = auth.currentUser ? await getIdToken(auth.currentUser) : null

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }

      config.headers['x-locale'] = Cookies.get(cookieName.LOCALE) || 'en'

      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  instance.interceptors.response.use(
    response => response.data,
    (err) => {
      console.error(err)
      const customErr = new Error(err?.response?.data?.errorMessage || 'Unknown Error')
      customErr['code'] = err?.response?.data?.error || 'UNKNOWN_ERROR'
      throw customErr
    }
  )


  return instance
}

export const cmClient = createClient({
  baseURL: urlConfig.apiDomain,
  headers: {
    platform: 'web',
    version: appConfig.version
  },
})
