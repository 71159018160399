import { CssBaseline, ThemeProvider } from '@mui/material'
import type { FC } from 'react'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import ModalContainer from 'react-modal-promise'
import { useRoutes } from 'react-router-dom'
import { SplashScreen } from '~/components/app/SplashScreen'
import { gtmConfig } from '~/config'
import { useAuth } from '~/hooks/useAuth'
import useScrollReset from '~/hooks/useScrollReset'
import { gtm } from '~/lib/gtm'
import routes from '~/routes'
import { createTheme } from '~/theme'

const App: FC = () => {
  const content = useRoutes(routes)

  const { isInitialized } = useAuth()

  useScrollReset()

  useEffect(() => {
    gtm.initialize(gtmConfig)
  }, [])

  const theme = createTheme({})

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position='top-center' />
      <ModalContainer />
      {isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  )
}

export default App
