import type { Theme } from '@mui/material'
import {
  Box,
  Divider,
  Drawer,
  Link,
  Typography,
  useMediaQuery
} from '@mui/material'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { ReactNode, useEffect, useMemo } from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { LanguageSelector } from '~/components/app/LanaguageSelector'
import { Scrollbar } from '~/components/app/Scrollbar'
import { CmAutomation } from '~/icons/CmAutomation'
import { CmChannel } from '~/icons/CmChannel'
import { CmContact } from '~/icons/CmContact'
import { CmHome } from '~/icons/CmHome'
import { CmInbox } from '~/icons/CmInbox'
import { CmSetting } from '~/icons/CmSetting'
import { TeamRole } from '~/types/team'
import { DashboardSidebarSection } from './DashboardSidebarSection'

interface DashboardSidebarProps {
  onClose: () => void
  open: boolean
}

interface Item {
  title: string;
  children?: Item[]
  chip?: ReactNode
  icon?: ReactNode
  path?: string
  role?: TeamRole[]
}

interface Section {
  title: string;
  items: Item[];
}

const getSections = (t: TFunction): Section[] => [
  {
    title: '',
    items: [
      {
        title: t('sideMenu.getStart'),
        icon: <CmHome fontSize={'inherit'} />,
        path: '/'
      },
      {
        title: t('sideMenu.inbox'),
        icon: <CmInbox fontSize={'inherit'} />,
        path: '/inbox'
      },
      {
        title: t('sideMenu.contact'),
        icon: <CmContact fontSize={'large'} />,
        path: '/contact'
      },
      {
        title: t('sideMenu.automation'),
        icon: <CmAutomation fontSize={'large'} />,
        path: '/automation'
      },
      {
        title: t('sideMenu.channel'),
        icon: <CmChannel fontSize={'large'} />,
        path: '/channel'
      },
      {
        title: t('sideMenu.setting.title'),
        icon: <CmSetting fontSize={'large'} />,
        path: '/setting',
        children: [
          {
            title: t('sideMenu.setting.account'),
            path: '/setting/account'
          },
          {
            title: t('sideMenu.setting.team'),
            path: '/setting/team',
            role: [TeamRole.OWNER, TeamRole.ADMIN]
          },
          {
            title: t('sideMenu.setting.planBilling'),
            path: '/setting/billing',
            role: [TeamRole.OWNER, TeamRole.ADMIN]
          },
          {
            title: t('sideMenu.setting.quickReply'),
            path: '/setting/quick-reply'
          }
        ]
      }
    ]
  }
]

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onClose, open } = props
  const { t } = useTranslation()
  const location = useLocation()
  const lgUp = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up('lg'),
    {
      noSsr: true
    }
  )
  const sections = useMemo(() => getSections(t), [t])

  //  Auto close sidebar
  useEffect(() => {
    open && onClose?.()
  }, [location.pathname])

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >

        <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
          <Scrollbar
            sx={{
              height: '100%',
              '& .simplebar-content': {
                height: '100%'
              },
              flexGrow: 1
            }}
          >
            {sections.map((section) => (
              <DashboardSidebarSection
                key={section.title}
                path={location.pathname}
                sx={{
                  mt: 3,
                  '& + &': {
                    mt: 3
                  }
                }}
                {...section}
              />
            ))}
          </Scrollbar>
        </Box>

        <Box
          sx={{
            mt: 'auto',
            mx: 2,
            pb: 3,
            textAlign: 'center'
          }}
        >
          <Box sx={{ mb: 2 }}>
            <LanguageSelector />
          </Box>

          <Divider
            sx={{
              borderColor: 'neutral.600',
              mx: 2,
              mb: 2
            }}
          />

          <Link
            href='https://google.com'
            underline='none'
            color='text.primary'
          >
            <Typography variant='caption'>
              {t('sideMenu.needHelp')}
            </Typography>
          </Link>
        </Box>
      </Box>

    </>
  )

  //  Desktop drawer
  if (lgUp) {
    return (
      <Drawer
        anchor='left'
        open
        PaperProps={{
          sx: {
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
            width: 190,
            pt: { md: 8 }
          }
        }}
        variant='permanent'
      >
        {content}
      </Drawer>
    )
  }

  //  Mobile drawer
  return (
    <Drawer
      anchor='left'
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: 190
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant='temporary'
    >
      {content}
    </Drawer>
  )
}

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
}
