import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import 'nprogress/nprogress.css'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { HelmetProvider } from 'react-helmet-async'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-quill/dist/quill.snow.css'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '~/contexts/FirebaseAuthContext'
import '~/i18n'
import { store } from '~/store'
import App from './App'

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
)
