import { Auth } from '@firebase/auth'
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut as firebaseSignOut
} from 'firebase/auth'
import i18n from 'i18next'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { createContext, useCallback, useEffect, useReducer } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import cookieName from '~/constants/cookieName'
import { auth } from '~/lib/firebase'
import { useDispatch } from '~/store'
import { postAcceptMemberInvitation } from '~/store/slices/team'
import { getUser } from '~/store/slices/user'


interface State {
  isInitialized: boolean
  isAuthenticated: boolean
}

interface AuthContextValue extends State {
  auth: Auth
  signUpWithEmail: (
    email: string,
    password: string
  ) => Promise<any>
  signInWithEmail: (email: string, password: string) => Promise<any>
  signInWithGoogle: () => Promise<any>
  signOut: () => Promise<void>
  forgetPassword: (email: string) => Promise<void>
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED'
  payload: {
    isAuthenticated: boolean
  };
};

type Action = AuthStateChangedAction

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false
}

const reducer = (state: State, action: Action): State => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true
    }
  }

  return state
}

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  auth,
  signUpWithEmail: () => Promise.resolve(),
  signInWithEmail: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  forgetPassword: () => Promise.resolve()
})


interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props
  const [authState, authDispatch] = useReducer(reducer, initialState)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => onAuthStateChanged(auth, async (user) => {
    if (user) {
      try {
        await dispatch(getUser())
      } catch (err) {
        //  Check if all required user data set
        if (err.code === 'USER_ONBOARDING_REQUIRED') {
          //  Redirect user to onboarding
          navigate('/onboarding')
        }
      }

      authDispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true
        }
      })
    } else {
      authDispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: false
        }
      })
    }
  }), [authDispatch])

  const signInWithEmail = (
    email: string,
    password: string
  ): Promise<any> => signInWithEmailAndPassword(auth, email, password)

  const signInWithGoogle = (): Promise<any> => {
    const provider = new GoogleAuthProvider()
    return signInWithRedirect(auth, provider)
  }

  const signUpWithEmail = (
    email: string,
    password: string
  ): Promise<any> => createUserWithEmailAndPassword(auth, email, password)


  const signOut = (): Promise<void> => {
    return firebaseSignOut(auth)
  }

  const forgetPassword = (email: string): Promise<void> => sendPasswordResetEmail(auth, email)

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        auth,
        signUpWithEmail,
        signInWithGoogle,
        signInWithEmail,
        signOut,
        forgetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  )

}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}
