import pkg from '../package.json'

//  ENV
export const envConfig = {
  env: process.env.REACT_APP_ENV,
  local: process.env.REACT_APP_LOCAL === 'true'
}

export const appConfig = {
  version: pkg.version
}

export const urlConfig = {
  apiDomain: process.env.REACT_APP_API_DOMAIN
}

//  FIREBASE
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
}

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_ID
}
