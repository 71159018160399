import { User } from '~/types/user'
import { cmClient } from '~/utils/apiClient'

export const getUser = (): Promise<User> =>
  cmClient.get(`/users/me`)

export const patchUser = ({ user }: { user: Partial<User> }): Promise<User> =>
  cmClient.patch(`/users/me`, user)

export const postUserOnBoarding = ({ user }: { user: Partial<User> }): Promise<User> =>
  cmClient.post(`/users/onboarding`, user)

export const patchUserAvatar = (formData): Promise<User> =>
  cmClient.patch(`/users/me/avatar`, formData)
