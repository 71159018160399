import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  Typography
} from '@mui/material'
import { TFunction } from 'i18next'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath } from 'react-router'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useGetUser } from '~/hooks/query/useGetUser'
import { CmProfile } from '~/icons/CmProfile'
import { CmQuickReply } from '~/icons/CmQuickReply'
import { CmTeam } from '~/icons/CmTeam'
import { CmWallet } from '~/icons/CmWallet'
import { TeamRole } from '~/types/team'

const getSections = (t: TFunction): any[] => [
  {
    title: t('setting.item.account.title'),
    description: t('setting.item.account.description'),
    icon: <CmProfile fontSize={'inherit'} />,
    path: '/setting/account'
  },
  {
    title: t('setting.item.team.title'),
    description: t('setting.item.team.description'),
    icon: <CmTeam fontSize={'inherit'} />,
    path: '/setting/team',
    role: [TeamRole.OWNER, TeamRole.ADMIN]
  },
  {
    title: t('setting.item.planBilling.title'),
    description: t('setting.item.planBilling.description'),
    icon: <CmWallet fontSize={'inherit'} />,
    path: '/setting/plan-billing',
    role: [TeamRole.OWNER, TeamRole.ADMIN]
  },
  {
    title: t('setting.item.quickReply.title'),
    description: t('setting.item.quickReply.description'),
    icon: <CmQuickReply fontSize={'inherit'} />,
    path: '/quick-reply',
  }
]


const SidebarItem = ({ item, path }) => {
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, path) : false

  return (
    <ListItemButton
      sx={{
        alignItems: 'start',
        borderLeftWidth: 4,
        borderLeftStyle: 'solid',
        borderColor: exactMatch ? 'primary.main' : 'transparent'
      }}
      to={item.path}
      component={path && RouterLink}
    >
      <ListItemIcon sx={{ p: 0.5 }}>
        {item.icon}
      </ListItemIcon>
      <Box>
        <Typography variant='subtitle2'>
          {item.title}
        </Typography>
        <Typography variant='caption' sx={{ color: 'neutral.700' }}>
          {item.description}
        </Typography>
      </Box>
    </ListItemButton>
  )
}

const SettingSidebar = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { team } = useGetUser()

  const filteredMenu = useMemo(() => {
    return _.filter(getSections(t), item => {
      if (item?.role && !!item?.role.length) {
        return item.role.includes(team.role)
      }
      return true
    })
  }, [team])

  return (
    <List>
      {
        filteredMenu.map(item => (
            <SidebarItem
              item={item}
              path={location.pathname}
              key={item.path}
            />
          )
        )
      }
    </List>
  )
}

export default React.memo(SettingSidebar)
