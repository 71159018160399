import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import cookieName from '~/constants/cookieName'
import en from './locales/en.json'
import zhCN from './locales/zh-CN.json'
import zhHK from './locales/zh-HK.json'

const resources = {
  en: {
    translation: en
  },
  'zh-HK': {
    translation: zhHK
  },
  'zh-CN': {
    translation: zhCN
  }
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    detection: {
      lookupCookie: cookieName.LOCALE,
      caches: ['cookie'],
      cookieMinutes: 60 * 24 * 365 * 10,
    },
    supportedLngs: ['zh-HK', 'zh-CN', 'en']
  })

export default i18next
