import type { ListItemProps } from '@mui/material'
import { Box, Button, Collapse, List, ListItem } from '@mui/material'
import { SxProps } from '@mui/system'
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { CmArrowDown } from '~/icons/CmArrowDown'
import { CmArrowRight } from '~/icons/CmArrowRight'

interface DashboardSidebarItemProps extends ListItemProps {
  active?: boolean
  children?: ReactNode
  chip?: ReactNode
  depth: number
  icon?: ReactNode
  info?: ReactNode
  open?: boolean
  path?: string
  title: string
}

export const DashboardSidebarItem: FC<DashboardSidebarItemProps> = (props) => {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props
  const [open, setOpen] = useState<boolean>(openProp)

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  let paddingLeft = 20

  if (depth > 0) {
    paddingLeft = 20 + 28 * depth
  }

  const isCompact: boolean = depth > 0 && !children

  const buttonStyle: SxProps = {
    backgroundColor: active && !isCompact && 'primary.light',
    borderRadius: 0,
    color: active ? 'primary.main' : 'neutral.800',
    justifyContent: 'flex-start',
    pl: `${paddingLeft}px`,
    pr: 2,
    py: isCompact ? 0 : 1,
    textAlign: 'left',
    textTransform: 'none',
    width: '100%',
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
    borderColor: active && !isCompact ? 'primary.main' : 'transparent',
    '&:hover': {
      backgroundColor: !active && 'primary.light',
      color: 'primary.main'
    },
    '& .MuiButton-startIcon': {
      mr: 1.5
    },
    '& .MuiButton-endIcon > *:nth-of-type(1)': {
      fontSize: '10px'
    }
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          mb: 0.5,
          p: 0
        }}
        {...other}
      >
        <Button
          onClick={handleToggle}
          startIcon={icon}
          endIcon={!open ?
            <CmArrowRight fontSize='small' /> :
            <CmArrowDown fontSize='small' />}
          sx={buttonStyle}
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
        <Collapse
          in={open}
          sx={{ mt: 0.5 }}
        >
          <List>
            {children}
          </List>
        </Collapse>
      </ListItem>
    )
  }

  //  Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: isCompact ? 1 : 2,
        p: 0
      }}
    >
      <Button
        component={path && RouterLink}
        startIcon={icon}
        endIcon={chip}
        disableRipple
        sx={buttonStyle}
        variant='text'
        to={path}
      >
        <Box sx={{ flexGrow: 1 }}>
          {title}
        </Box>
        {info}
      </Button>
    </ListItem>
  )
}

DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
}

DashboardSidebarItem.defaultProps = {
  active: false,
  open: false,
}
