import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

interface LogoProps {
  width?: number
  height?: number
}

export const Logo = styled((props: LogoProps) => {
  const { ...other } = props

  return (
    <img src='/static/logo/logo.svg' alt='ChatMonster Logo' {...other} />
  )
})``

Logo.defaultProps = {}

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}
