import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '~/hooks/useAuth'

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props
  const { isAuthenticated } = useAuth()
  const location = useLocation()

  if (!isAuthenticated) {
    let redirectQuery = ''
    const rtValue = location.pathname + location.search + location.hash
    if (rtValue !== '/')
      redirectQuery = '?rt=' + encodeURIComponent(rtValue)

    return <Navigate to={`/signin${redirectQuery}`} replace={true} />
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node
}

export default AuthGuard
