import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import api from '~/api'
import type { AppThunk } from '~/store'
import { Pagination } from '~/types/app'
import { ChannelInfo, SocialPageData } from '~/types/channel'

export interface ChannelState {
  teamChannelList: ChannelInfo[]
}

const initialState: ChannelState = {
  teamChannelList: []
}

const slice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    getTeamChannelList(state: ChannelState, action: PayloadAction<ChannelInfo[]>): void {
      state.teamChannelList = action.payload
    }
  }
})

export const { reducer } = slice

export const getTeamChannelList = (): AppThunk<Promise<{
  channels: ChannelInfo[],
  pagination: Pagination
}>> =>
  async (dispatch) => {
    const resData = await api.channel.getTeamChannelList()
    dispatch(slice.actions.getTeamChannelList(resData.channels))
    return resData
  }

export const getConnectFacebookRedirectUrl = (): AppThunk<Promise<string>> =>
  async () => {
    const resData = await api.channel.getConnectFacebookRedirectUrl()
    return resData?.redirectURL || ''
  }

export const getConnectFacebookResult = (payload: any): AppThunk<Promise<SocialPageData[]>> =>
  async () => {
    const resData = await api.channel.getConnectFacebookResult(payload)
    const pageData = _.result(resData, 'data', [])

    const fbPageDataList: SocialPageData[] = pageData.map(page => ({
      id: page.id,
      name: page.name,
      accessToken: page.access_token
    }))

    return fbPageDataList
  }

export const postSubscribeFacebook = (payload: { id: string, accessToken: string }): AppThunk =>
  async (dispatch) => {
    return await api.channel.postSubscribeFacebook(payload)
  }

export const deleteFacebookPage = (payload: { pageId: string }): AppThunk =>
  async (dispatch) => {
    await api.channel.deleteFacebookPage(payload)
    await dispatch(getTeamChannelList())
    return true
  }

export const getConnectIgDmRedirectUrl = (): AppThunk<Promise<string>> =>
  async () => {
    const resData = await api.channel.getConnectIgDmRedirectUrl()
    return resData?.redirectURL || ''
  }

export const getConnectIgDmResult = (payload: any): AppThunk<Promise<SocialPageData[]>> =>
  async () => {
    const resData = await api.channel.getConnectIgDmResult(payload)
    const pageData = _.result(resData, 'data', [])

    const withIgPage = _.filter(pageData, item => !_.isNil(item.connected_instagram_account))

    const igUserDataList: SocialPageData[] = withIgPage.map(page => ({
      id: page.id,
      name: page.connected_instagram_account.username,
      accessToken: page.access_token
    }))

    return igUserDataList
  }

export const postSubscribeIgDm = (payload: { id: string, accessToken: string }): AppThunk =>
  async (dispatch) => {
    return await api.channel.postSubscribeIgDm(payload)
  }

export const deleteIgDmPage = (payload: { pageId: string }): AppThunk =>
  async (dispatch) => {
    await api.channel.deleteIgDmPage(payload)
    await dispatch(getTeamChannelList())
    return true
  }
