import { createSvgIcon } from '@mui/material/utils'

export const CmArrowRight = createSvgIcon(
  <>
    <path
      d='M7.04709 2.1576L6.73008 2.47457C6.51999 2.68466 6.51999 3.0253 6.73008 3.23543L15.4946 12L6.73008 20.7646C6.51999 20.9747 6.51999 21.3153 6.73008 21.5255L7.04709 21.8424C7.25718 22.0525 7.59782 22.0525 7.80791 21.8424L17.27 12.3804C17.48 12.1703 17.48 11.8297 17.27 11.6196L7.80791 2.1576C7.59782 1.94747 7.25718 1.94747 7.04709 2.1576Z'
    />
  </>,
  'CmArrowRight'
)
