import { createSvgIcon } from '@mui/material/utils'

export const CmTeam = createSvgIcon(
  <>
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M3.21084 14.9426C3.98613 14.1673 5.03765 13.7318 6.13408 13.7318H12.838C13.9344 13.7318 14.9859 14.1673 15.7612 14.9426C16.5365 15.7179 16.9721 16.7694 16.9721 17.8658V19.5418C16.9721 19.9738 16.6219 20.3239 16.1899 20.3239C15.758 20.3239 15.4078 19.9738 15.4078 19.5418V17.8658C15.4078 17.1843 15.1371 16.5306 14.6551 16.0487C14.1732 15.5668 13.5196 15.296 12.838 15.296H6.13408C5.45252 15.296 4.79887 15.5668 4.31693 16.0487C3.835 16.5306 3.56425 17.1843 3.56425 17.8658V19.5418C3.56425 19.9738 3.21408 20.3239 2.78212 20.3239C2.35017 20.3239 2 19.9738 2 19.5418V17.8658C2 16.7694 2.43555 15.7179 3.21084 14.9426Z'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M9.48603 5.24015C8.06675 5.24015 6.9162 6.3907 6.9162 7.80998C6.9162 9.22926 8.06675 10.3798 9.48603 10.3798C10.9053 10.3798 12.0559 9.22926 12.0559 7.80998C12.0559 6.3907 10.9053 5.24015 9.48603 5.24015ZM5.35196 7.80998C5.35196 5.52679 7.20285 3.6759 9.48603 3.6759C11.7692 3.6759 13.6201 5.52679 13.6201 7.80998C13.6201 10.0932 11.7692 11.9441 9.48603 11.9441C7.20285 11.9441 5.35196 10.0932 5.35196 7.80998Z'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M17.9466 14.4273C18.0546 14.0091 18.4812 13.7576 18.8994 13.8656C19.7863 14.0946 20.5721 14.6116 21.1333 15.3356C21.6944 16.0595 21.9993 16.9493 22 17.8653V19.5419C22 19.9738 21.6498 20.324 21.2179 20.324C20.7859 20.324 20.4358 19.9738 20.4358 19.5419V17.8661C20.4353 17.2969 20.2457 16.7439 19.897 16.2939C19.5481 15.8439 19.0597 15.5225 18.5084 15.3802C18.0901 15.2722 17.8386 14.8456 17.9466 14.4273Z'
    />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M14.5943 4.373C14.7014 3.95454 15.1275 3.70217 15.546 3.80932C16.4352 4.037 17.2234 4.55417 17.7862 5.2793C18.3491 6.00443 18.6546 6.89626 18.6546 7.8142C18.6546 8.73215 18.3491 9.62398 17.7862 10.3491C17.2234 11.0742 16.4352 11.5914 15.546 11.8191C15.1275 11.9262 14.7014 11.6739 14.5943 11.2554C14.4871 10.837 14.7395 10.4109 15.158 10.3037C15.7107 10.1622 16.2007 9.84071 16.5506 9.38995C16.9005 8.9392 17.0904 8.38482 17.0904 7.8142C17.0904 7.24359 16.9005 6.68921 16.5506 6.23845C16.2007 5.7877 15.7107 5.46621 15.158 5.32468C14.7395 5.21754 14.4871 4.79146 14.5943 4.373Z'
    />
  </>,
  'CmTeam'
)
