import { Pagination } from '~/types/app'
import {
  Team,
  TeamInvitation,
  TeamInvitationInfo,
  TeamMember,
  TeamRole
} from '~/types/team'
import { cmClient } from '~/utils/apiClient'

export const getTeam = ({ teamId }: { teamId: string }): Promise<Team> =>
  cmClient.get(`/teams/${teamId}`)

export const patchTeam = ({
  teamId,
  team
}: { teamId: string, team: Partial<Team> }): Promise<Team> =>
  cmClient.patch(`/teams/${teamId}`, team)

export const postCreateTeam = ({ team }: { team: Partial<Team> }): Promise<Team> =>
  cmClient.post(`/teams`, team)

export const getTeamMembers = ({ teamId }: { teamId: string }): Promise<{
  members: TeamMember[],
  pagination: Pagination
}> =>
  cmClient.get(`/teams/${teamId}/members`)

export const patchTeamMember = ({
  teamId,
  memberId,
  role
}: { teamId: string, memberId: string, role: TeamRole }) =>
  cmClient.patch(`/teams/${teamId}/members/${memberId}`, {
    role
  })

export const deleteTeamMember = ({
  teamId,
  memberId
}: { teamId: string, memberId: string }) =>
  cmClient.delete(`/teams/${teamId}/members/${memberId}`)

export const getTeamInvitations = ({ teamId }: { teamId: string }): Promise<{
  invitations: TeamInvitation[],
}> =>
  cmClient.get(`/teams/${teamId}/invitations`)

export const postMemberInvitation = ({
  teamId,
  email,
  role
}: { teamId: string, email: string, role: TeamRole }) =>
  cmClient.post(`/teams/${teamId}/invitations`, {
    email,
    role
  })

export const postResendMemberInvitation = ({
  teamId,
  invitationId
}: { teamId: string, invitationId: string }) =>
  cmClient.post(`/teams/${teamId}/invitations/${invitationId}/resend`)

export const deleteMemberInvitation = ({
  teamId,
  invitationId
}: { teamId: string, invitationId: string }) =>
  cmClient.delete(`/teams/${teamId}/invitations/${invitationId}`)

export const postAcceptMemberInvitation = ({
  teamId,
  invitationId
}: { teamId: string, invitationId: string }) =>
  cmClient.post(`/teams/${teamId}/invitations/${invitationId}/accept`)

export const getValidateMemberInvitation = ({
  teamId,
  invitationId
}: { teamId: string, invitationId: string }): Promise<TeamInvitationInfo> =>
  cmClient.get(`/teams/${teamId}/invitations/${invitationId}`)

export const patchTeamLogo = ({
  teamId,
  formData
}: { teamId: string, formData: FormData }): Promise<Team> =>
  cmClient.patch(`/teams/${teamId}/logo`, formData)
