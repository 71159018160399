import { Avatar } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { FC } from 'react'
import { CmProfile } from '~/icons/CmProfile'

interface AvatarCircularProps {
  src: string
  sx?: SxProps
  placeholder?: React.ReactNode
}

const AvatarCircular: FC<AvatarCircularProps> = (props) => {
  const { src, sx, placeholder } = props

  return (
    <Avatar
      src={src}
      sx={sx}
    >
      {placeholder ? placeholder : <CmProfile />}
    </Avatar>
  )
}

export default AvatarCircular
