import { User } from '~/types/user'

export type Team = {
  id: string
  name: string
  logoURL: string
  websiteURL: string
  size: string
  industryType: string
  role: TeamRole
}

export enum TeamRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  AGENT = 'AGENT'
}

export type TeamMember = {
  role: TeamRole,
  user: Partial<User>
}

export type TeamInvitation = {
  createdAt: number
  email:string
  invitationId: string
  lastSentAt: number
  role: TeamRole
  status: string
  teamId: string
}

export type TeamInvitationInfo ={
  invitationId: string
  inviterName: string
  role: TeamRole
  teamId: string
  teamName: string
}
