import { createSvgIcon } from '@mui/material/utils'

export const CmQuickReply = createSvgIcon(
  <g fill='none'>
    <path
      d='M3.58 5.16h13.84c1.66 0 3 1.34 3 3v3.32'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.74 2 3.58 5.16l3.16 3.16M20.42 18.84H6.58c-1.66 0-3-1.34-3-3v-3.32'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m17.26 22 3.16-3.16-3.16-3.16'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </g>,
  'CmQuickReply'
)
