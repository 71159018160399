import * as channel from '~/api/channel'
import * as team from '~/api/team'
import * as user from '~/api/user'


export default {
  user,
  team,
  channel
}
