import { keyframes } from '@emotion/react'
import { Box } from '@mui/material'
import type { FC } from 'react'
import { LogoIcon } from '~/components/app/LogoIcon'

const iconPulse = keyframes`
    from, to {
        transform: scale3d(1, 1, 1)
    }
    
    50% {
        transform: scale3d(1.03, 1.03, 1.03)
    }
`

export const SplashScreen: FC = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'neutral.100',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 2000
    }}
  >
    <LogoIcon
      sx={{
        height: 80,
        width: 80,
        animation: `${iconPulse} 1s ease-in-out infinite`
      }}
    />
  </Box>
)
