import { List, ListItem, ListProps } from '@mui/material'
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { matchPath } from 'react-router'
import { useGetUser } from '~/hooks/query/useGetUser'
import { TeamRole } from '~/types/team'
import { DashboardSidebarItem } from './DashboardSidebarItem'

interface Item {
  title: string
  path?: string
  icon?: ReactNode
  chip?: ReactNode
  info?: ReactNode
  children?: Item[]
  role?: TeamRole[]
}

interface DashboardSidebarSectionProps extends ListProps {
  items: Item[]
  path: string
  title: string
}

const renderNavItems = ({ role, depth = 0, items, path }: {
  role: TeamRole
  depth?: number
  items: Item[]
  path: string
}) => (
  <ListItem disablePadding>
    <List disablePadding key={path} sx={{ width: '100%' }}>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
          role,
          acc,
          item,
          depth,
          path
        }),
        []
      )}
    </List>
  </ListItem>
)

const reduceChildRoutes = ({
  role,
  acc,
  item,
  depth,
  path
}: {
  role: TeamRole
  acc: JSX.Element[]
  depth: number
  item: Item
  path: string
}): Array<JSX.Element> => {
  const key = `${item.path}-${depth}`

  const partialMatch = item.path ? !!matchPath({
    path: item.path,
    end: false
  }, path) : false

  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, path) : false


  if (!item?.role || item.role.includes(role)) {
    if (item.children) {
      acc.push(
        <DashboardSidebarItem
          active={partialMatch}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={partialMatch}
          path={item.path}
          title={item.title}
        >
          {renderNavItems({
            role,
            depth: depth + 1,
            items: item.children,
            path
          })}
        </DashboardSidebarItem>
      )
    } else {
      acc.push(
        <DashboardSidebarItem
          active={exactMatch}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
        />
      )
    }
  }

  return acc
}

export const DashboardSidebarSection: FC<DashboardSidebarSectionProps> = (props) => {
  const { items, path, title, ...other } = props

  const {team} = useGetUser()

  return (
    <List {...other}>
      {renderNavItems({
        role: team.role,
        items,
        path
      })}
    </List>
  )
}

DashboardSidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
