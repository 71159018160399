import { Pagination } from '~/types/app'
import { ChannelInfo } from '~/types/channel'
import { cmClient } from '~/utils/apiClient'

export const getTeamChannelList = (): Promise<{
  channels: ChannelInfo[],
  pagination: Pagination
}> =>
  cmClient.get(`/channels`)

export const getConnectFacebookRedirectUrl = (): Promise<{ redirectURL: string }> =>
  cmClient.get(`/channels/facebook`)

export const getConnectFacebookResult = (payload: any): Promise<any> =>
  cmClient.get(`/channels/facebook/connect`, { params: payload })

export const postSubscribeFacebook = (payload: { id: string, accessToken: string }): Promise<any> =>
  cmClient.post(`/channels/facebook/subscribe`, payload)

export const getFacebookPageStatus = (payload: { pageId: string }): Promise<any> =>
  cmClient.get(`/channels/facebook/${payload.pageId}/status`)

export const deleteFacebookPage = (payload: { pageId: string }): Promise<any> =>
  cmClient.delete(`/channels/facebook/${payload.pageId}`)

export const getConnectIgDmRedirectUrl = (): Promise<{ redirectURL: string }> =>
  cmClient.get(`/channels/instagram`)

export const getConnectIgDmResult = (payload: any): Promise<any> =>
  cmClient.get(`/channels/instagram/connect`, { params: payload })

export const postSubscribeIgDm = (payload: { id: string, accessToken: string }): Promise<any> =>
  cmClient.post(`/channels/instagram/subscribe`, payload)

export const getIgDmPageStatus = (payload: { pageId: string }): Promise<any> =>
  cmClient.get(`/channels/instagram/${payload.pageId}/status`)

export const deleteIgDmPage = (payload: { pageId: string }): Promise<any> =>
  cmClient.delete(`/channels/instagram/${payload.pageId}`)


