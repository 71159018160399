import { lazy, Suspense } from 'react'
import type { RouteObject } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import LoadingScreen from '~/components/app/LoadingScreen'
import AuthGuard from '~/components/auth/AuthGuard'
import { DashboardLayout } from '~/components/layout/DashboardLayout'
import { SettingLayout } from '~/components/layout/SettingLayout'

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
)

const Home = Loadable(lazy(() => import('~/pages/Home')))

//  Auth
const SignIn = Loadable(lazy(() => import('~/pages/account/SignIn')))
const SignUp = Loadable(lazy(() => import('~/pages/account/SignUp')))
const ForgetPassword = Loadable(lazy(() => import('~/pages/account/ForgetPassword')))
const UserManagement = Loadable(lazy(() => import('~/pages/account/UserManagement')))

//  Team Invitation
const TeamInvitation = Loadable(lazy(() => import('~/pages/account/TeamInvitation')))

//  On boarding
const OnBoarding = Loadable(lazy(() => import('~/pages/account/OnBoarding')))


//  Main app
const GetStart = Loadable(lazy(() => import('~/pages/app/GetStart')))
const Inbox = Loadable(lazy(() => import('~/pages/app/Inbox')))
const Contact = Loadable(lazy(() => import('~/pages/app/Contact')))
const Automation = Loadable(lazy(() => import('~/pages/app/Automation')))
const Channel = Loadable(lazy(() => import('~/pages/app/Channel')))

//  Channel
const SetupFacebook = Loadable(lazy(() => import('~/pages/app/channel/SetupFacebook')))
const SetupInstagram = Loadable(lazy(() => import('~/pages/app/channel/SetupInstagram')))

//  Setting
const Account = Loadable(lazy(() => import('~/pages/app/setting/Account')))
const Team = Loadable(lazy(() => import('~/pages/app/setting/Team')))

const routes: RouteObject[] = [
  {
    path: '*',
    element: <Navigate to='/signin' replace />
  },
  {
    path: 'usermgmt',
    element: <UserManagement />
  },
  {
    path: 'signin',
    element: <SignIn />
  },
  {
    path: 'signup',
    element: <SignUp />
  },
  {
    path: 'forget-password',
    element: <ForgetPassword />
  },
  {
    path: 'team/invitation',
    element: <TeamInvitation />
  },
  {
    path: 'onboarding',
    element: <OnBoarding />
  },
  {
    path: 'forget-password',
    element: <Home />
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <GetStart />
      },
      {
        path: 'inbox',
        element: <Inbox />
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: 'automation',
        element: <Automation />
      },
      {
        path: 'channel',
        children: [
          {
           path: '',
            element: <Channel />,
          },
          {
            path: 'facebook',
            element: <SetupFacebook />
          },
          {
            path: 'facebook/connect',
            element: <SetupFacebook />
          },
          {
            path: 'instagram',
            element: <SetupInstagram />
          },
          {
            path: 'instagram/connect',
            element: <SetupInstagram />
          }
        ]
      },
      {
        path: 'setting',
        element: <SettingLayout />,
        children: [
          {
            path: '',
            element: <Navigate to='/setting/account' replace />
          },
          {
            path: 'account',
            element: <Account />
          },
          {
            path: 'team',
            element: <Team />
          }
        ]
      }
    ]
  }

]

export default routes
