import { Button, Menu, MenuItem } from '@mui/material'
import _ from 'lodash'
import type { FC } from 'react'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { CmGlobe } from '~/icons/CmGlobe'

const languageOptions = {
  en: {
    label: 'language.options.english'
  },
  'zh-HK': {
    label: 'language.options.chinese'
  },
  'zh-CN': {
    label: 'language.options.chineseSimplified'
  }
}

export const LanguageSelector: FC = () => {
  const { i18n, t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (language: string) => {
    handleClose()
    i18n.changeLanguage(language)
    toast.success(t('language.changed') as string)
  }

  const selectedLang = _.keys(languageOptions).includes(i18n.language) ?
    i18n.language :
    'en'

  return (
    <div>
      <Button
        fullWidth
        onClick={handleClick}
        startIcon={<CmGlobe />}
        variant='outlined'
        size='small'
        color='secondary'
        sx={{
          borderRadius: '50rem'
        }}
      >
        {t(languageOptions[selectedLang].label)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem onClick={() => handleChange(language)} key={language}>
            {t(languageOptions[language].label)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
