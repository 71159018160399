import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { DashboardNavbar } from '~/components/dashboard/DashboardNavbar'
import { DashboardSidebar } from '~/components/dashboard/DashboardSidebar'

interface DashboardLayoutProps {
  children?: ReactNode
}

const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 190
    }
  })
)

export const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
            p: 2
          }}
        >
          <Outlet />
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onOpenSidebar={(): void => setIsSidebarOpen(true)} />
      <DashboardSidebar
        onClose={(): void => setIsSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node
}
